import React from "react";

const AboutPage = () => {
  return (
    <div style={{ padding: "2rem", textAlign: "center" }}>
      <h1>About</h1>
      <h2>How it Works</h2>
      <p>This page is under construction.</p>
    </div>
  );
};

export default AboutPage;
